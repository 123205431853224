<template>
  <div>
    <Row :gutter="8" class="m-b-10">
      <i-col span="12">
        <span class="text-14">{{ orderInfo.advertiserName }}，{{ htmlToSchedule(demandStartDate, demandEndDate) }}</span>
      </i-col>
      <!-- <i-col
        span="12"
        class="text-right"
      >
        <Button
          type="success"
          size="small"
          @click="handleSubmitTask"
        >提交全部任务</Button>
      </i-col> -->
    </Row>
    <div class="m-b-10">
      <RadioGroup type="button" size="small" v-model="query.productId" @on-change="changeProduct">
        <Radio :label="item.productId" v-for="item in demandProductArray" :key="item.productId">{{ item.productName }}
        </Radio>
      </RadioGroup>
    </div>
    <Row :gutter="8" class="m-b-10">
      <i-col span="3">
        <Select v-model="assetId" size="small" :clearable="true" placeholder="选择资产" @on-change="assetChange">
          <Option v-for="item in assetArray" :key="'asset_' + item.assetId" :value="item.assetId">{{ item.assetName }}
          </Option>
        </Select>
      </i-col>
      <i-col span="3">
        <Select v-model="query.stationId" size="small" :clearable="true" placeholder="选择站点" @on-change="stataionChange">
          <Option v-for="item in stationArray" :key="'station_' + item.stationId" :value="item.stationId">
            {{ item.stationName }}</Option>
        </Select>
      </i-col>
      <i-col span="3">
        <Select v-model="query.position" size="small" :clearable="true" placeholder="选择位置">
          <Option v-for="item in positionArray" :key="'position_' + item.position" :value="item.position">
            {{ item.positionName }}</Option>
        </Select>
      </i-col>
      <i-col span="3">
        <Select v-model="query.status" size="small" :clearable="true" placeholder="选择状态">
          <Option v-for="item in statusArray" :key="'status_' + item.value" :value="item.value">{{ item.name }}</Option>
        </Select>
      </i-col>
      <i-col span="3">
        <Select size="small" v-model="query.taskResourcePublishFlag" :clearable="true" placeholder="在刊状态">
          <Option v-for="item in publishStatusArray" :key="'pubSta_' + item.value" :value="item.value">{{ item.name }}
          </Option>
        </Select>
      </i-col>
      <i-col span="5">
        <Input v-model="query.keyword" size="small" placeholder="资源编号、画面名称" />
      </i-col>
      <i-col span="2">
        <Button type="primary" icon="ios-search" size="small" @click="handleSearch">搜索</Button>
      </i-col>
    </Row>
    <Row :gutter="8" class="m-b-5">
      <i-col span="12">
        <Checkbox class="m-r-5" v-model="query.removeSetted" @on-change="handleSearch">未设置</Checkbox>
        <Button type="primary" size="small" class="m-r-5"
          :disabled="selectedTaskStatus !== -2 || selectedTaskIds.length === 0" @click="handleModifySet">更改设置</Button>
        <Button type="primary" size="small" class="m-r-5" v-if="demandType !== 2 && basicData === 2"
          :disabled="selectedTaskStatus !== -2 || selectedTaskIds.length === 0" @click="handleModifyLx">更改连刊</Button>
        <Button type="error" size="small" class="m-r-5" v-if="demandType !== 2"
          :disabled="selectedTaskStatus !== -2 || selectedTaskIds.length === 0" @click="handleDeleteSet">删除设置</Button>
        <Button type="success" size="small" class="m-r-5" :disabled="selectedTaskIds.length === 0"
          @click="handleSubmitTask">提交任务</Button>
        <Button type="error" size="small" class="m-r-5"
          :disabled="![-3, 0, 1, 2, 4].includes(selectedTaskStatus) || selectedTaskIds.length === 0"
          @click="handleCancelTask">撤销任务</Button>
        <Button v-if="demandType === 1" type="success" size="small" @click="handleVisualSetting">可视化设置</Button>
        <!-- <Button
          type="success"
          @click="handleSubmitTask"
        >提交任务</Button> -->
      </i-col>
      <i-col span="12">
        <div class="paging_style">
          <Page size="small" :total="tableData.totalRow" :page-size="query.pageSize" show-total show-sizer
            :page-size-opts="pagesizeSetting" @on-page-size-change='changePageSize' @on-change="changePage"
            :current="query.pageNumber"></Page>
        </div>
      </i-col>
    </Row>
    <div>
      <Table stripe max-height="620" size="small" ref="selection" :data="tableData.list" :columns="showColumns"
        :loading="dataLoading" @on-selection-change="selectionChange"></Table>
      <Row :gutter="8">
        <i-col span="12">
          <div class="m-t-5" style="line-height:25px;">当前已选中<span style="color:#ff9900;padding:0 3px">{{
            selectedTaskIds.length }}</span>条</div>
        </i-col>
        <i-col span="12">
          <div class="paging_style">
            <Page size="small" :total="tableData.totalRow" :page-size="query.pageSize" show-total show-elevator
              show-sizer :page-size-opts="pagesizeSetting" @on-page-size-change='changePageSize' @on-change="changePage"
              :current="query.pageNumber"></Page>
          </div>
        </i-col>
      </Row>

    </div>
    <Modal v-model="visiblePreview" width="800">
      <p slot="header" class="text-center">{{ fileName }} {{ variableTitle }}</p>
      <div>
        <Row class="m-b-5">
          <i-col span="12">
            <div v-if="showCheckbox" class="m-b-10">
              <Checkbox v-model="pictureCheck" @on-change="handleCheckChange">是否可用于生成报告</Checkbox>
            </div>
          </i-col>
          <i-col :span="!showCheckbox ? 24 : 12" class="text-right">
            <Button size="small" type="success" @click="viewBigImage">查看原图</Button>
          </i-col>
        </Row>

        <img-light-box ref="imgLightBox" :showModal="visiblePreview" :sortIndex.sync="sortIndex"></img-light-box>
      </div>
      <div slot="footer" class="text-left">
        <div v-if="serialArray && serialArray.length">
          <div>
            <h4 class="workplatform-title m-t-10 m-b-20">对应序号</h4>
          </div>
          <div>
            <Tag color="primary" v-for="item in serialArray" :key="'serial_' + item">{{ item }}</Tag>
          </div>
        </div>
      </div>
    </Modal>
    <TaskSubmit ref="taskSubmit" :taskId="curTaskId" :demandIds="selectedDemandIds" :successFun="handleSubmitSuccess" />
    <WorkOrderList ref="workOrderList" :list="demandTaskList" />
  </div>
</template>

<script>
// import { mapState } from 'vuex'
import { sysMixins } from '@/assets/mixins/sys'
import { demandMixins } from '@/assets/mixins/demand'
import { commonMixins } from '@/assets/mixins/common'
import { formatEnable, formartMspTaskitemStatus, formartMspTaskitemRephotoStatus } from '@/utils/tagStatus'
// import { GetCurrentSchedule } from '@/utils/dateFormat'
// import { updateDemand, updateDemandTaskitem } from '@/api/msp/demand'
// import { getResourceParameter } from '@/api/msp/task'
import { reportPicMark } from '@/api/msp/taskitem'
import { getDemandAttachList } from '@/api/msp/demandV3'
import { getProductList, updateLx, submit, applyRevokeTaskitem, getResourceParameter, deleteTaskitem } from '@/api/order/demand'
import ImgLightBox from '@/components/common/ImgVideoLightBox'
import TaskSubmit from './TaskSubmit.vue'
import WorkOrderList from './WorkOrderList.vue'
export default {
  mixins: [sysMixins, demandMixins, commonMixins],
  components: {
    ImgLightBox,
    TaskSubmit,
    WorkOrderList
  },
  props: {
    serviceType: { // 服务对象类型，1：销售，2：代理商
      type: Number,
      default: 1
    }
  },
  data () {
    return {
      assetId: null,
      pagesizeSetting: [15, 50, 100, 200, 500],
      query: {
        productId: null,
        assetIds: '',
        stationId: null,
        position: null,
        status: null,
        keyword: '',
        pictureResource: null,
        supplierCompanyId: null,
        showCurrentScreen: true,
        demandMainId: null,
        taskResourcePublishFlag: 1,
        removeSetted: false,
        pageNumber: 1,
        pageSize: 15
      },
      statusArray: [
        {
          value: -2,
          name: '草稿'
        },
        {
          value: 0,
          name: '处理中'
        },
        {
          value: 1,
          name: '已派单'
        },
        {
          value: 2,
          name: '执行中'
        },
        {
          value: 3,
          name: '已完成'
        }

      ],
      publishStatusArray: [
        { value: 2, name: '在刊' },
        { value: 3, name: '下刊' }
      ],
      demandProductArray: [],
      showColumns: [],
      tableColumns: [],
      showTableColumns: new Map([
        ['printMedia', ['selection', 'resourceCode', 'stationName', 'positionName', 'actionDate', 'side', 'pictureSource', 'currentScrren', 'file', 'fileName', 'status', 'remark', 'finishPublish', 'revocation', 'feedback', 'operation']], // 平面媒体
        ['streamMedia', ['selection', 'resourceCode', 'stationName', 'positionName', 'actionDate', 'launchTime', 'unitDuration', 'playTimes', 'tduration', 'pictureSource', 'currentScrren', 'file', 'fileName', 'status', 'remark', 'finishPublish', 'revocation', 'feedback', 'operation']] // 流媒体
      ]),
      visiblePreview: false,
      fileName: '',
      variableTitle: '', // 标量标题
      sortIndex: 0,
      showCheckbox: false, // 显示是否用于报告生成按钮
      pictureCheck: false,
      currentFileArray: [], // 当前预览照片反馈集合
      serialArray: [],
      showSerial: false, // 查看素材是否显示序列号
      actionDate: '', // 上刊设置作业日期
      cancelRemark: '', // 取消任务原因
      stationArray: [],
      dataLoading: false,
      deleteProduce: false, // 删除制作
      selectedPictureSource: [], // 选中任务的素材来源
      // selectedDemandIds: [],
      demandTaskList: []

    }
  },
  created () {
    this.getDemandProductData()
    this.$store.commit('set_operating_type', 2)
  },
  destroyed () {
    // 组件注销时，清空当前组件产生非公用数据
    // this.$store.commit('set_resource_data', {})
    this.$store.commit('set_selected_taskIds', [])
    this.$store.commit('set_selected_productId', null)
    this.$store.commit('set_page_query', {})
    this.$store.commit('set_temp_selected_item', null)
    this.$store.commit('set_operating_type', 1)
    this.tableData = []
    this.selectedResourceIds = []
    this.selectedTaskIds = []
    this.selectedDemandIds = []
    this.selectedPictureSource = []
    // this.setMaxSide = 0
  },
  computed: {
    curTaskId () {
      return this.$store.state.installSetting.currentTaskId
    },
    demandStatus () {
      return this.$store.state.installSetting.demandStatus
    },
    demandType () {
      return this.$store.state.installSetting.demandType
    },
    basicData () {
      return this.$store.state.installSetting.basicData
    },
    tableData: {
      get () {
        var results = this.$store.state.installSetting.tableData
        if (results.list) {
          let curMspTaskId = -1
          results.list.forEach(item => {
            item._disabled = (item.status === 3 || item.hangUpStatus === 2) // 新增“挂起”判断
            if (this.$store.state.installSetting.selectedTaskIds.some(x => x === item.id)) {
              item._checked = true
            }

            if (item.mspTaskId > 0) {
              curMspTaskId = item.mspTaskId
            }
          })
          this.$store.commit('set_cur_msp_taskId', curMspTaskId)
        }

        return results
      },
      set (val) {
        this.$store.commit('set_table_data', val)
      }

    },
    selectedTaskIds: {
      get () {
        return this.$store.state.installSetting.selectedTaskIds
      },
      set (val) {
        this.$store.commit('set_selected_taskIds', val)
      }
    },
    selectedResourceIds: {
      get () {
        return this.$store.state.installSetting.selectedResourceIds
      },
      set (val) {
        this.$store.commit('set_selected_resourceIds', val)
      }
    },
    selectedTaskStatus: {
      get () {
        return this.$store.state.installSetting.selectedTaskStatus
      },
      set (val) {
        this.$store.commit('set_selected_task_status', val)
      }
    },
    tempSelectedTaskItem: { // 临时存储分页保留之前选中的taskItem
      get () {
        return this.$store.state.installSetting.tempSelectedItem
      },
      set (val) {
        this.$store.commit('set_temp_selected_item', val)
      }
    },
    selectedDemandIds: {
      get () {
        return this.$store.state.installSetting.selectedDemandIds
      },
      set (val) {
        this.$store.commit('set_selected_demand_ids', val)
      }
    }
  },
  methods: {
    getTableColumns () {
      const tableColumns = {
        selection: { type: 'selection', width: 60, align: 'center', fixed: 'left' },
        resourceCode: { title: '资源编号', key: 'code', width: 100, fixed: 'left' },
        stationName: { title: '所属站台', key: 'stationName', width: 100, fixed: 'left' },
        positionName: { title: '位置', key: 'positionName', align: 'center', minWidth: 80 },
        actionDate: {
          title: '作业日期',
          key: 'actionDate',
          align: 'center',
          minWidth: 120,
          render: (h, params) => {
            return h('div', [
              h('p', params.row.actionDate),
              h('Tag', {
                props: { color: params.row.actionDateModifyStatus === 1 ? 'default' : 'primary' }
              }, params.row.actionDateModifyStatusName)
            ])
          }
        },
        side: { title: '画面数', key: 'side', align: 'center', minWidth: 80 },
        unitDuration: { title: '规格(秒)', key: 'unitDuration', align: 'center', minWidth: 80 },
        playTimes: { title: '投放次数', key: 'playTimes', align: 'center', minWidth: 100 },
        tduration: { title: '总时长(秒)', key: 'tduration', align: 'center', minWidth: 100 },
        pictureSource: { title: '素材来源', key: 'picturesourceName', align: 'center', minWidth: 100 },
        launchTime: {
          title: '投放时间',
          align: 'center',
          minWidth: 150,
          render: (h, params) => {
            return h('span', `${params.row.startTime} - ${params.row.endTime}`)
          }
        },
        currentScrren: {
          title: '当前素材',
          minWidth: 100,
          align: 'center',
          key: 'currentScrren',
          render: (h, params) => {
            if (params.row.currentFileList && params.row.currentFileList.length > 0) {
              if (params.row.currentFileList.some(x => (x.mimeType.includes('video') || x.mimeType.includes('audio')))) {
                return h('a', {
                  on: {
                    click: () => {
                      const postData = {
                        startIndex: 0,
                        imgList: params.row.currentFileList
                      }
                      this.sortIndex = 0
                      this.fileName = `${params.row.stationName} ${params.row.code}`
                      this.variableTitle = ''
                      this.$refs.imgLightBox.init(postData)
                      this.showSerial = false
                      this.showCheckbox = false
                      this.handleCurFileSerial()
                      this.visiblePreview = true
                    }
                  },
                  style: {
                    cursor: 'pointer'
                  }
                }, '查看素材')
              } else {
                return h('div', [
                  h(
                    'img',
                    {
                      domProps: {
                        src: params.row.currentFileList[0].fileKey + '?x-oss-process=image/resize,w_64'
                      },
                      on: {
                        click: () => {
                          const postData = {
                            startIndex: 0,
                            imgList: params.row.currentFileList
                          }
                          this.sortIndex = 0
                          this.fileName = `${params.row.stationName} ${params.row.code}`
                          this.variableTitle = ''
                          this.$refs.imgLightBox.init(postData)
                          this.showSerial = false
                          this.handleCurFileSerial()
                          this.showCheckbox = false
                          this.visiblePreview = true
                        }
                      },
                      style: {
                        width: '64px',
                        cursor: 'pointer'
                      }
                    }
                  )
                ])
              }
            } else {
              return h('span', '暂无素材')
            }
          }
        },
        file: {
          title: '上刊素材',
          minWidth: 100,
          align: 'center',
          key: 'fileId',
          render: (h, params) => {
            if (params.row.demandFileList && params.row.demandFileList.length > 0) {
              if (params.row.demandFileList.some(x => (x.mimeType.includes('video') || x.mimeType.includes('audio')))) {
                return h('a', {
                  on: {
                    click: () => {
                      const postData = {
                        startIndex: 0,
                        imgList: params.row.demandFileList
                      }
                      this.sortIndex = 0
                      this.fileName = `${params.row.stationName} ${params.row.code}`
                      this.currentFileArray = params.row.demandFileList
                      this.$refs.imgLightBox.init(postData)
                      this.showSerial = true
                      this.handleCurFileSerial()
                      this.variableTitle = ''
                      this.showCheckbox = false
                      this.visiblePreview = true
                    }
                  },
                  style: {
                    cursor: 'pointer'
                  }
                }, '查看素材')
              } else {
                return h('div', [
                  h(
                    'img',
                    {
                      domProps: {
                        src: params.row.demandFileList[0].fileKey + '?x-oss-process=image/resize,w_64'
                      },
                      on: {
                        click: () => {
                          const postData = {
                            startIndex: 0,
                            imgList: params.row.demandFileList
                          }
                          this.sortIndex = 0
                          this.currentFileArray = params.row.demandFileList
                          this.fileName = `${params.row.stationName} ${params.row.code}`
                          this.variableTitle = ''
                          this.$refs.imgLightBox.init(postData)
                          this.showSerial = true
                          this.handleCurFileSerial()
                          this.showCheckbox = false
                          this.visiblePreview = true
                        }
                      },
                      style: {
                        width: '64px',
                        cursor: 'pointer'
                      }
                    }
                  )
                ])
              }
            } else {
              return h('span', '暂无素材')
            }
          }
        },
        fileName: {
          title: '上刊名称',
          align: 'center',
          minWidth: 100,
          render: (h, params) => {
            const name = Array.from(new Set(params.row.demandFileList.map(x => x.fileName))).toString()
            return h('Tooltip', {
              props: {
                content: name,
                transfer: true,
                'max-width': '200px'
              }
            }, name.length > 12 ? name.substr(0, 11) + '...' : name)
          }
        },
        // supplier: {
        //   title: '供应商',
        //   align: 'center',
        //   minWidth: 100,
        //   render: (h, params) => {
        //     return h('span', params.row.taskitemLabor.companyName)
        //   }
        // },
        status: {
          title: '状态',
          minWidth: 120,
          align: 'center',
          render: (h, params) => {
            const html = [
              formartMspTaskitemStatus(h, params.row.status, params.row.statusName),
              formartMspTaskitemRephotoStatus(h, params.row.rephoto)
            ]
            if (params.row.hangUpStatus === 2) { // 挂起
              html.push(h('div', [
                h('Tag', {
                  props: {
                    color: 'warning'
                  }
                }, '已挂起')
              ]))
            }
            return h('div', html)
          }
        },
        remark: {
          title: '备注',
          align: 'center',
          minWidth: 100,
          render: (h, params) => {
            const remark = `${params.row.remark} ${params.row.extra}`
            return h('Tooltip', {
              props: {
                content: remark,
                transfer: true,
                'max-width': '200px'
              }
            }, remark.length > 12 ? remark.substring(0, 11) + '...' : remark)
          }
        },
        finishPublish: {
          title: '已下刊',
          minWidth: 90,
          align: 'center',
          render: (h, params) => {
            return formatEnable(h, params.row.isFinishPublish)
          }
        },
        revocation: {
          title: '撤销申请',
          minWidth: 90,
          align: 'center',
          render: (h, params) => {
            return formatEnable(h, params.row.revocation)
          }
        },
        // {
        //   title: '是否连刊',
        //   minWidth: 80,
        //   align: 'center',
        //   render: (h, params) => {
        //     return formatEnable(h, params.row.lx)
        //   }
        // },
        feedback: {
          title: '照片反馈',
          align: 'center',
          minWidth: 100,
          render: (h, params) => {
            var html = []
            // if (params.row.taskitemAttachs && params.row.taskitemAttachs.length) {
            html.push(h('a', {
              style: { marginRight: '5px', cursor: 'pointer' },
              on: {
                click: () => {
                  const curTaskInfo = (params.row.demandTaskList && params.row.demandTaskList.length) ? params.row.demandTaskList.find(x => x.type === this.demandType) : null
                  this.fileName = `${params.row.stationName} ${params.row.code}`
                  // this.variableTitle = params.row.finishTime ? `（完成时间：${params.row.finishTime}）` : ''
                  this.variableTitle = curTaskInfo ? `（完成时间：${curTaskInfo.finishTime}）` : ''
                  this.showCheckbox = true
                  this.showSerial = false
                  this.onViewFeedback(params.row)
                  // this.handleCurFileSerial()
                }
              }
            }, '照片反馈'))
            return h('div', html)
            // }
          }
        },
        operation: {
          title: '操作',
          align: 'center',
          fixed: 'right',
          minWidth: 200,
          render: (h, params) => {
            const html = []
            if (params.row.demandTaskList && params.row.demandTaskList.length) {
              html.push(
                h('a', {
                  class: ['m-r-5'],
                  on: {
                    click: () => {
                      this.onViewWorkOrders(params.row)
                    }
                  }
                }, '查看工单列表')
              )
            }
            if (params.row.status === -2) {
              html.push(h('a', {
                style: { marginRight: '5px' },
                on: {
                  click: () => {
                    this.$store.commit('set_current_resourceId', params.row.deviceId)
                    this.$store.commit('set_selected_orderItemDetailId', params.row.orderItemDetailId)

                    this.selectedTaskIds = [params.row.id]
                    this.handelSetPublishingScreen()
                  }
                }
              }, '设置刊中画面'))
            }
            return h('div', html)
          }
        }
      }

      const data = []
      let columnsKey = 'printMedia'
      if ([28, 155, 202, 203, 429, 430].includes(this.query.productId)) { // 电子媒体
        columnsKey = 'streamMedia'
      }
      const showColumns = this.showTableColumns.get(columnsKey)
      if (showColumns && showColumns.length) {
        showColumns.forEach(col => data.push(tableColumns[col]))
      }
      if (this.demandType === 2) { // 监播去掉操作列
        data.pop()
      }
      return data
    },
    /**
    * 获取列表数据
    */
    getTableData () {
      // this.setMaxSide = 0
      this.dataLoading = true
      this.tableData = {}
      this.query.demandMainId = this.curTaskId
      this.query.assetIds = this.assetId ? JSON.stringify([this.assetId]) : ''
      this.$store.dispatch('getTaskitemData', this.query).finally(() => { this.dataLoading = false })
    },
    getDemandProductData () {
      getProductList({ demandMainId: this.curTaskId }).then(res => {
        if (res && !res.errcode) {
          this.demandProductArray = res
          if (this.demandProductArray && this.demandProductArray.length) {
            this.query.productId = this.demandProductArray[0].productId
            this.changeProduct()
          }
        } else {
          this.demandProductArray = []
        }
      })
    },
    changeProduct () {
      this.assetId = null
      this.query.pageNumber = 1
      this.query.assetIds = ''
      this.query.stationId = null
      this.query.position = null
      this.query.keyword = ''
      this.selectedTaskIds = []
      this.selectedDemandIds = []
      this.selectedResourceIds = []
      this.tempSelectedTaskItem = []
      this.selectedPictureSource = []
      this.query.removeSetted = false
      this.$store.commit('set_selected_productId', this.query.productId)

      this.getAssetArray()
      this.getTableData()
    },
    getAssetArray () {
      getResourceParameter({ demandMainId: this.curTaskId, productId: this.query.productId }).then(res => {
        if (res && !res.errcode) {
          this.assetArray = res
        } else {
          this.assetArray = []
        }
      })
    },
    assetChange () {
      this.query.stationId = ''
      this.query.position = ''
      if (this.assetId) {
        const asset = this.assetArray.find(x => x.assetId === this.assetId)
        this.stationArray = asset ? asset.stationList : []
      } else {
        this.stationArray = []
      }
    },
    handleSearch () {
      this.query.pageNumber = 1
      this.selectedTaskIds = []
      this.selectedDemandIds = []
      this.selectedResourceIds = []
      this.tempSelectedTaskItem = []
      this.selectedPictureSource = []
      this.getTableData()
    },
    changePageSize (pagesize) {
      this.query.pageNumber = 1
      this.query.pageSize = pagesize
      this.selectedTaskIds = []
      this.selectedDemandIds = []
      this.selectedResourceIds = []
      this.tempSelectedTaskItem = []
      this.selectedPictureSource = []
      this.getTableData(this.query)
    },
    changePage (curPage) {
      this.query.pageNumber = curPage
      this.getTableData(this.query)
    },
    selectionChange (selection) {
      this.selectedTaskIds = []
      this.selectedDemandIds = []
      this.selectedResourceIds = []
      this.selectedPictureSource = []
      var tempSelectedTaskStatus = []

      // 最终选中的所有taskItem
      // const curSelection = selection.map(x => {
      //   return { id: x.id, resourceId: x.resourceId, status: x.status }
      // })
      const tempSelections = []
      const curSelection = selection.map(x => {
        return { id: x.orderItemDetailId, resourceId: x.resourceId, deviceId: x.deviceId, status: x.status, unitDuration: x.unitDuration, playTimes: x.playTimes, pictureSource: x.picturesource, demandId: x.demandId }
      })
      this.handelPageSelections(this.tempSelectedTaskItem, curSelection, this.query.pageNumber)
      this.tempSelectedTaskItem.forEach(item => {
        this.selectedTaskIds = this.selectedTaskIds.concat(item.existSelections.map(x => x.id))
        this.selectedDemandIds = this.selectedDemandIds.concat(item.existSelections.map(x => x.demandId))
        this.selectedResourceIds = this.selectedResourceIds.concat(item.existSelections.map(x => x.deviceId))
        this.selectedPictureSource = this.selectedPictureSource.concat(item.existSelections.map(x => x.pictureSource))
        tempSelectedTaskStatus = tempSelectedTaskStatus.concat(item.existSelections.map(x => x.status))
        // PIS屏获取所选项规格和投放次数
        if ([28].includes(this.query.productId)) {
          item.existSelections.forEach(child => {
            if (!tempSelections.some(x => x.unitDuration === child.unitDuration && x.playTimes === child.playTimes)) {
              tempSelections.push({ unitDuration: child.unitDuration, playTimes: child.playTimes })
            }
          })
        }
      })

      const selectedTaskStatusArray = Array.from(new Set(tempSelectedTaskStatus))
      this.selectedTaskStatus = selectedTaskStatusArray[0]

      // PIS屏只能相同规格和相同次数进行批量操作
      if ([28].includes(this.query.productId)) {
        if (tempSelections.length > 1) {
          this.selectedResourceIds = []
          this.selectedPictureSource = []
          this.$Notice.warning({ desc: '请选择规格相同且投放次数相同的资源进行批量操作' })
        } else {
          this.$store.commit('set_selected_stmedia_attr', tempSelections[0])
        }
      } else {
        if (selectedTaskStatusArray.length > 1) {
          this.selectedTaskIds = []
          this.selectedDemandIds = []
          this.selectedResourceIds = []
          this.selectedPictureSource = []
          this.selectedTaskStatus = null
          this.$Notice.warning({ desc: '请选择相同状态的任务进行批量操作' })
        }
      }
    },
    /**
     * 更新上刊设置
     */
    handleModifySet () {
      this.$store.commit('set_show_left_block', true)
      // if (this.demandType === 7) {
      //   this.$store.commit('set_left_component', 'SetUninstall')
      // }
      if (this.demandType === 2) { // 监播
        this.$store.commit('set_left_component', 'MonitorDemand')
      } else {
        this.$store.commit('set_left_component', 'SetScreen')
      }
    },
    /**
     * 更新连刊
     */
    handleModifyLx () {
      this.$Modal.confirm({
        title: '操作提示',
        content: '确定将所选数据设置连刊？',
        onOk: () => {
          const params = {
            demandMainId: this.curTaskId,
            orderitemDetailIds: JSON.stringify(this.selectedTaskIds)
          }
          updateLx(params).then(res => {
            if (res && res.errcode === 0) {
              this.selectedTaskIds = []
              this.selectedDemandIds = []
              this.selectedResourceIds = []
              this.selectedPictureSource = []
              this.tempSelectedTaskItem = []
              this.$store.dispatch('getTaskitemData')
              this.$Notice.success({ desc: '操作成功' })
            }
          })
        }
      })
    },
    handleDeleteSet () {
      this.$Modal.confirm({
        title: '操作提示',
        content: '确定要删除所选数据的上刊设置？',
        onOk: () => {
          deleteTaskitem({ demandIds: JSON.stringify(this.selectedDemandIds) }).then(res => {
            if (res && res.errcode === 0) {
              this.selectedTaskIds = []
              this.selectedDemandIds = []
              this.selectedResourceIds = []
              this.tempSelectedTaskItemIds = []
              this.selectedPictureSource = []

              this.$Notice.success({ desc: '操作成功' })
              this.getTableData()
              this.$store.dispatch('getDemandProgressData', { demandMainId: this.curTaskId })
            }
          })
        }
      })
    },
    /**
     * 取消任务
     */
    handleCancelTask () {
      this.deleteProduce = false
      this.$Modal.confirm({
        title: '操作提示',
        render: (h) => {
          const html = []
          // 选中任务的素材来源存在”新制作“，则提示是否取消制作任务
          if (this.selectedPictureSource.includes(1)) {
            html.push(h('p', {
              class: ['m-b-10']
            }, '当前存在制作任务，是否要取消制作任务？'))
            html.push(h('Checkbox', {
              props: {
                autofocus: true,
                value: this.deleteProduce
              },
              class: ['m-b-10'],
              on: {
                input: (val) => {
                  this.deleteProduce = val
                }
              }
            }, '取消制作任务'))
          }
          html.push(h('Input', {
            props: {
              type: 'textarea',
              rows: 4,
              value: this.cancelRemark,
              autofocus: true,
              placeholder: '填写取消任务原因，非必填'
            },
            on: {
              input: (val) => {
                this.cancelRemark = val
              }
            }
          }))
          return h('div', html)
          // return h('Input', {
          //   props: {
          //     type: 'textarea',
          //     rows: 4,
          //     value: this.cancelRemark,
          //     autofocus: true,
          //     placeholder: '填写取消任务原因，非必填'
          //   },
          //   on: {
          //     input: (val) => {
          //       this.cancelRemark = val
          //     }
          //   }
          // })
        },
        onOk: () => {
          applyRevokeTaskitem({ demandIds: JSON.stringify(this.selectedDemandIds), deleteProduce: this.deleteProduce, remark: this.cancelRemark }).then(res => {
            if (res && res.errcode === 0) {
              this.selectedTaskIds = []
              this.selectedDemandIds = []
              this.selectedResourceIds = []
              this.tempSelectedTaskItem = []
              this.selectedPictureSource = []
              this.getTableData()
              this.$Notice.success({ desc: res.errmsg })
            }
          })
        }
      })
    },
    handleSubmitTask () {
      if (this.demandType === 1) { // 上刊任务提交
        this.$refs.taskSubmit.init()
      } else { // 目前只有监播提交
        this.$Modal.confirm({
          title: '操作提示',
          content: '确定要提交该任务？',
          onOk: () => {
            submit({ demandMainId: this.curTaskId, demandIds: JSON.stringify(this.selectedDemandIds) }).then(res => {
              if (res && res.errcode === 0) {
                this.handleSubmitSuccess()
              }
            })
          }
        })
      }

      // this.$Modal.confirm({
      //   title: '操作提示',
      //   content: '确定要提交该任务？',
      //   onOk: () => {
      //     if (this.demandType === 7) {
      //       submitUninstallApply({ taskId: this.curTaskId, taskitemIds: JSON.stringify(this.selectedTaskIds) }).then(res => {
      //         if (res && res.errcode === 0) {
      //           this.handleSubmitSuccess()
      //         }
      //       })
      //     } else {
      //       submit({ taskId: this.curTaskId, taskitemIds: JSON.stringify(this.selectedTaskIds) }).then(res => {
      //         if (res && res.errcode === 0) {
      //           this.handleSubmitSuccess()
      //         }
      //       })
      //     }
      //   }
      // })
    },
    handleSubmitSuccess () {
      this.selectedTaskIds = []
      this.selectedDemandIds = []
      this.selectedResourceIds = []
      this.tempSelectedTaskItem = []
      this.selectedPictureSource = []
      this.getTableData()

      this.$Notice.success({ desc: '操作成功' })
    },
    /**
     * 反馈照片是否用于报告生成
     */
    handleCheckChange () {
      const attachId = this.currentFileArray[this.sortIndex].id
      const currentChecked = this.currentFileArray[this.sortIndex].checked // 获取当前操作照片状态
      const data = {
        attachId: attachId,
        isMark: this.pictureCheck
      }
      reportPicMark(data).then(res => {
        if (res && res.errcode === 0) {
          this.$Notice.success({ desc: '设置成功' })
          this.currentFileArray[this.sortIndex].checked = !currentChecked
          this.getTableData()
        }
      })
    },
    /**
     * 设置刊中画面
     */
    handelSetPublishingScreen () {
      this.setLeftComponent('SetPublishingPicSource')
      this.setShowLeftBlock(true)
    },
    /**
     * 获取当前画面所在的梯牌号
     */
    handleCurFileSerial () {
      this.serialArray = []
      if (this.showSerial) { this.serialArray = this.currentFileArray[this.sortIndex].serials ? JSON.parse(this.currentFileArray[this.sortIndex].serials) : [] }
    },
    /**
     * 查看反馈素材
     */
    onViewFeedback (params) {
      this.serialArray = []
      this.sortIndex = 0
      const postData = {
        startIndex: this.sortIndex,
        imgList: []
      }

      const taskitemIds = (params.demandTaskList && params.demandTaskList.length) ? params.demandTaskList.map(x => x.taskitemId) : []
      if (taskitemIds.length === 0) {
        this.$Notice.warning({ desc: '暂无反馈素材' })
        return false
      }
      getDemandAttachList({ taskitemIds: JSON.stringify(taskitemIds) }).then(res => {
        if (res && res.length === 0) {
          this.$Notice.warning({ desc: '暂无反馈素材' })
          return false
        }
        postData.imgList = res
        this.currentFileArray = res
        if (this.showSerial) { this.serialArray = this.currentFileArray[this.sortIndex].serials ? JSON.parse(this.currentFileArray[this.sortIndex].serials) : [] }
        this.pictureCheck = this.currentFileArray[0].checked
        this.$refs.imgLightBox.init(postData)
        this.visiblePreview = true
      })
    },
    /**
     * 查看原图
     */
    viewBigImage () {
      this.$refs.imgLightBox.viewBigImg()
    },
    handleVisualSetting () {
      this.$store.commit('set_show_left_block', false)
      this.$store.commit('set_bottom_component', 'VisualSetting')
    },
    onViewWorkOrders (obj) {
      this.demandTaskList = obj.demandTaskList
      this.$nextTick(() => {
        this.$refs.workOrderList.init()
      })
    }
  },
  watch: {
    sortIndex: {
      deep: true,
      immediate: true,
      handler (val) {
        this.pictureCheck = this.showCheckbox ? this.currentFileArray[val].checked : false
        // this.variableTitle = this.currentFileArray[val].uploadTime ? `（上传时间：${this.currentFileArray[val].uploadTime}）` : ''
        this.handleCurFileSerial()
      }
    },
    selectedTaskStatus (val) {
      // 选中任务非草稿状态清除左侧组件
      if (val !== -2) {
        this.setLeftComponent('')
        this.setShowLeftBlock(false)
      }
    },
    'query.productId' (val) {
      if (val) {
        this.showColumns = this.getTableColumns()
      }
    }
  }
}
</script>
